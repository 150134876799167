@font-face {
    font-family: 'Mario64';
    src: url('fonts/Mario64.eot');
    src: url('fonts/Mario64.eot?#iefix') format('embedded-opentype'),
        url('fonts/Mario64.woff2') format('woff2'),
        url('fonts/Mario64.woff') format('woff'),
        url('fonts/Mario64.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Variables */
$blue: #68b7fa;
$tan: #fdecdf;
$white: #ffffff;
$black: #000000;

$gold: #d6ac0e;
$green: #259200;
$gray: #abb1ac;
$pink: #eb87a1;
$yellow: #efbb19;
$blue: #347bdf;
$buttonGrey: #434a43;

*,
*::before,
*::after {
    box-sizing: border-box;
}

::selection {
    background-color: $black;
    color: white;
}

html {
    background-color: $black;
    overflow: hidden;
    height: 100vh;
}

body {
    max-width: 3840px;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    background-color: $black;
    background-image: url('images/bg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    font-family: 'Mario64';
    font-variation-settings: "wdth" 100;
}

.background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(120, 120, 120, 0.2), rgba(120, 120, 120, 0.2) 33%, rgba(140, 140, 140, 0.2) 0%, rgba(140, 140, 140, 0.2));
    background-size: auto 5px;
    background-repeat: repeat-y;
    z-index: 9999999999;
    pointer-events: none;
    opacity: .75;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    background-image:url('images/overlay-bg.png');
    background-repeat: repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99999999;
    @media only screen and (max-width: 3840px) {
        background-size: 12.5vw;
    }
    @media only screen and (max-width: 1920px) {
        background-size: 16.6666vw;
    }
    @media only screen and (max-width: 1600px) {
        background-size: 20vw;
    }
    @media only screen and (max-width: 1280px) {
        background-size: 25vw;
    }
    @media only screen and (max-width: 800px) {
        background-size: 33vw;
    }
    @media only screen and (max-width: 500px) {
        background-size: 50vw;
    }
    img {
        max-width: 40vw;
        width: 100%;
        @media only screen and (max-width: 1000px) {
            max-width: 80vw;
        }
        @media only screen and (min-width: 1024px) and (max-height: 750px) {
            max-width: 400px;
        }
    }
    button {
        font-family: 'Mario64';
        font-size: 4rem;
        line-height: 1;
        display: inline-flex;
        color: #ffffff;
        background: transparent !important;
        width: auto;
        z-index: 2;
        text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        0 0 10px rgba(255, 255, 255, 0.6),
        0 0 15px rgba(255, 255, 255, 0.4);
        padding-left: 30px;
        padding-right: 24px;
        border: 0;
        transform: scale(1);
        transition: .3s all ease;
        margin-top: 20px;
        width: 100%;
        max-width: 100%;
        display: block;
        animation: growShrink 2s infinite ease-in-out;

        @media only screen and (max-width: 600px) {
            font-size:3rem;
        }

        @media only screen and (max-width: 430px) {
            font-size:2rem;
            margin-top: 10px;
        }
    
        &:hover {
            cursor: pointer;
            transform: scale(1.05);
    
            @media only screen and (max-width: 600px) {
                transform: scale(1);
            }
        }
    }
}

#bowserLogo {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform-origin: 0 0;
    transition: all 3s ease-in-out;
}

@keyframes growShrink {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

#second-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 99999998;
    display: none; // Initially hidden
    align-items: center;
    justify-content: center;
    transition: opacity 500ms ease-in-out; // Transition for fading
}

#begin-btn {
    img {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
}

/* Keyframe for the fade-in effect */
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    /* 3 */
}

.content-container {
    position: relative;
    margin-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    top: 0;
    margin-top: 0;
    padding-top: 15px;
    @media only screen and (max-width: 768px) {
        padding-bottom: 15px;

    }
    img {
        display: block;
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
}


.game-content {
    position: absolute;
    left: 20%;
    top: 25vh;

    @media only screen and (max-width: 1800px) {
        left: 15%;
    }
    @media only screen and (max-width: 1600px) {
        left: 11%;
    }
    @media only screen and (max-width: 1440px) {
        left: 8%;
    }
    @media only screen and (max-width: 768px) {
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.game-content-container {
    background: rgba(0,0,0,.8);
    color: white;
    width: 500px;
    height: 315px;
    padding: 25px;
    @media only screen and (max-width: 1000px) {
        width: 375px;
        height: 300px;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        min-height: 200px;
        height: auto;
    }

    p {
        font-size: 24px;
        line-height: 1.25;
        margin-top: 0;
        margin-bottom: 0;
        @media only screen and (max-width: 1000px) {
            font-size: 20px;
        }
        @media only screen and (max-width: 640px) {
            font-size: 15px;
        }
    }
}

.game-content-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    @media only screen and (max-width: 640px) {
        position: relative;
        margin-top: -35px;
    }
    @media only screen and (max-width: 380px) {
        position: relative;
        margin-top: -25px;
    }
    a {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        
    }
}

h2 {
    color: $white;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.25;

    @media only screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 1.50;
    }

    @media only screen and (max-width: 1024px) {
        max-width: 520px;
    }

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 15px;
    }

    @media only screen and (max-width: 470px) {
        font-size: 22px;
    }

    @media only screen and (max-width: 410px) {
        font-size: 21px;
    }

    @media only screen and (max-width: 376px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 374px) {
        font-size: 18px;
    }
    @media only screen and (max-width: 350px) {
        font-size: 14px;
    }

}

button, input[type="button"] {
    -webkit-appearance: none;
}


#sound-control {
    left: 0;
    font-family: 'Mario64';
    font-size: 2rem;
    line-height: 1;
    display: inline-flex;
    color: #ffffff;
    background: transparent !important;
    width: auto;
    z-index: 2;
    padding:0;
    border: 0;
    transform: scale(1);
    transition: .3s all ease;
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        @media only screen and (max-width: 600px) {
            transform: scale(1);
        }
    }
    @media only screen and (max-width: 640px) {
        font-size: 22px;
    }
    @media only screen and (max-width: 540px) {
        font-size: 20px;
    }
}

.banner-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;

    &:after {
        content: '';
        height: 100%;
        width: 50px;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

        @media only screen and (max-width: 415px) {
            width: 30px;
        }
    }
}

#banner {
    height: 60px;
    font-size: 16px;
    color: $white;
    background-color:$black;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-top: 2px solid #333;
    @media only screen and (max-width: 415px) {
        font-size: 13px;
    }
}

#banner span {
    display: inline-block;
    padding-right: 50px;
    /* Add space between repeated text */
}

#banner-title {
    background: black;
    border-top: 2px solid #333;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 245px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    @media only screen and (max-width: 1024px) {
        width: 205px;
    }
    @media only screen and (max-width: 415px) {
        padding-left: 5px;
        padding-right: 5px;
        width: 160px;
    }
    &:after{
        content: '';
        height: 100%;
        width: 50px;
        display: block;
        position: absolute;
        bottom: 0;
        right: -50px;
        background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        @media only screen and (max-width: 1024px) {
            right: -40px;
        }
        @media only screen and (max-width: 415px) {
            width: 30px;
            right: -30px;
        }
    }
    span {
        color: $white;
        display: block;
        font-size: 20px;
        position: relative;
        top: 2px;
        padding-left: 10px;
        font-style: italic;
        font-weight: bold;
        @media only screen and (max-width: 1024px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 415px) {
            font-size: 14px;
        }
    }
    img {
        width: 30px;
        margin-left: 0;
        position: absolute;
        top: 13px;
        left: 22px;
        transform: rotate(-15deg);
        @media only screen and (max-width: 1024px) {
            width: 24px;
            top: 15px;
        }
        @media only screen and (max-width: 415px) {
            width: 20px;
            top: 19px;
            left: 12px;
        }
    }
}

#banner .ticker {
    display: inline-block;
    animation: marquee 20s linear infinite;
    position: relative;
    top: 2px;
    /* Adjust speed as needed */

    @media only screen and (max-width: 415px) {
        animation: marquee 10s linear infinite;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

#contract-address-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: auto;
    background:transparent;
    z-index: 2;
    background:transparent;
    transform: scale(1);
    transition: all .3s ease;
    &:hover {
        transform: scale(1.05);
    }
    .copy {
        display: none;
        font-family: 'Mario64';
        text-transform: uppercase;
        padding-left: 10px;
        font-size: 32px;
        position: relative;
        top: -4px;
    }
}

#contract-address {
    font-size: 32px;
    color: #f4f4f4;
    padding: 10px 0 10px 10px;
    border-radius: 4px;
    position: relative;
    top: 1px;
    @media only screen and (max-width: 640px) {
        font-size: 22px;
    }
    @media only screen and (max-width: 540px) {
        font-size: 20px;
    }
}

#copy-btn {
    background:transparent;
    color: white;
    border: none;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    transform: scale(1);
    transition: .3s all ease;
    img {
        width: 25px;
        height: 25px;
        top: 3px;
        position: relative;
        @media only screen and (max-width: 640px) {
            width: 21px;
            height: 21px;
        }
    }
}

#copy-btn:hover {
    transform: scale(1.05);
}

#copy-status {
    font-size: 18px;
    color: white;
    margin-left: 10px;
    position: absolute;
    top: -15px;
    text-transform: none;
    img {
        width: 25px;
        height: 25px;
        position: relative;
        top: 2px;
    }
    @media only screen and (max-width: 540px) {
        top: inherit;
        bottom: -7px;
        font-size: 14px;
    }
}

.centered {
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
    margin-top: 50px;
    user-select: none;
    z-index: 9999999;
    display: block;
    @media only screen and (max-width: 415px) {
        margin-top: 25px;
    }
    
}

// HORIZONTAL WHITE
.road-rage {
    font-family: 'Mario64';
    font-size: 12rem;
    font-style: italic;
    line-height: 1;
    display: inline-flex;
    color: #ffffff;
    /* White text */
    background: #ffffff;
    /* White background for the text fill */
    width: auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: url(#softGlow);
    z-index: 1;
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;

    @media only screen and (max-width: 1440px) {
        font-size: 10rem;
    }
    @media only screen and (max-width: 1225px) {
        font-size: 8rem;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 7rem;
    }
    @media only screen and (max-width: 900px) {
        font-size: 6rem;
    }
    @media only screen and (max-width: 724px) {
        font-size: 5rem !important; 
    }
    @media only screen and (max-width: 374px) {
        font-size: 3rem !important;
    }
}

.shine::after {
    content: attr(data-text);
    background-image: linear-gradient(0deg, transparent 0%, rgba(255, 255, 255, 0.5) 35%, transparent 60%);
    mix-blend-mode: soft-light;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-background-clip: text;
    background-size: 100% 100%;
    background-position: 100% 50%;
    z-index: 999;
}

.road-rage.offset {
    margin-left: -50px;

    @media only screen and (max-width: 724px) {
        margin-left: -10px;
    }

    @media only screen and (max-width: 415px) {
        margin-left: -20px;
    }
}


.gradient-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    z-index: 1;
    pointer-events: none;
    /* So it doesn't interfere with any clickable elements */
    animation: gradientAnimation 5s ease-in-out infinite;
    opacity: 1;
}
/* Animation for the gradient effect */
@keyframes gradientAnimation {
    0% {
        transform: scaleY(1);
        opacity: 1;
    }

    50% {
        transform: scaleY(1.2);
        /* Grow a bit */
        opacity: 0.8;
        /* Change opacity */
    }

    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}

.glow-svg {
    height: 0;
}

  #buy, #play {
    border: outset 1rem lighten($gray, 10%);
    background: $gray;
    transition: border-width 1s;
    cursor: pointer;
    width: 200px;
    height: 80px;
    color: $buttonGrey;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: .3s all ease;
    font-size: 28px;
    &:hover {
        transform: scale(1.05);
    }
    @media only screen and (max-width: 1000px) {
        width: 150px;
        height: 70px;
        font-size: 22px;
    }
  }

@media (min-width: 1024px) {
    * {
        cursor: none !important;
    }

    :root {
        cursor: none !important;
    }

    .cursor {
        position: fixed;
        width: 48px;
        height: 55px;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: 999999999;
        background-image: url('images/cursor.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position:4px 11px;
    }

    .hover-cursor {
        width: 48px;
        height: 48px;
        background-image: url('images/cursor-hover.png');
        background-position: 4px 11px;
    }
}

@media (max-width: 1023px) {

    /* Reset to default cursor behavior on smaller screens */
    .cursor {
        display: none !important;
    }
}


a.circle,
a.circle div {
    --shadow:
        inset 0 0 0 0.25vmin var(--color),
        inset 2.25vmin 2.25vmin 0.5vmin -2.25vmin #fff8,
        inset -2.25vmin -0.5vmin 1vmin -2.25vmin #fff8,
        inset -2.25vmin -0.5vmin 1.5vmin #fff2,
        inset 0.5vmin 1vmin 0.5vmin #0001,
        inset 1vmin 0vmin 0.5vmin #0001,
        inset -1.5vmin -1vmin 2vmin -0.5vmin #2583,
        inset -1vmin -0.5vmin 0.5vmin #fff3;
    width: 60px;
    /* Default width for screens above 1024px */
    height: 60px;
    /* Default height for screens above 1024px */
    border-radius: 50%;
    background: var(--color);
    z-index: 2;
    border-color: transparent;
    box-shadow:
        0 0 0 0.25vmin #0002,
        0 0 0.3vmin 0.1vmin #0008,
        0 0 0.3vmin 0.1vmin #fff4,
        0 0 0 0.2vmin #0002,
        var(--shadow);
    margin-left: 7px;
    margin-right: 7px;

    color: #333;
    text-shadow:
        1px 1px 2px #000,
        -1px -1px 2px #fff6;
    transition: .1s all ease;
    transform: scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Media query for screens at or below 1024px */
@media (max-width: 1024px) {

    a.circle,
    a.circle div {
        width: 50px;
        /* Set width to 50px for smaller screens */
        height: 50px;
        /* Set height to 50px for smaller screens */
    }
}

/* Hover effect */
a.circle:hover {
    box-shadow:
        0 0 0 0.25vmin #0004,
        0 0 0.3vmin 0.1vmin #0008,
        0 0 0.3vmin 0.1vmin #fff2,
        0 0 0 0.2vmin #0003,
        inset 0.5vmin 0.5vmin 0.5vmin #0003;
}

/* Click effect */
a.circle:active {
    box-shadow:
        0 0 0 0.25vmin #0004,
        0 0 0.3vmin 0.1vmin #0008,
        0 0 0.3vmin 0.1vmin #fff2,
        0 0 0 0.2vmin #0003,
        inset 0.5vmin 0.5vmin 0.5vmin #0003;
}

/* Individual button text color based on button color */
#x {
    --color: #3c62f8;
    color: #1f368a;
    text-shadow:
        1px 1px 2px #162766,
        -1px -1px 2px #5477d6;
}

#y {
    --color: #14b161;
    color: #0a6436;
    text-shadow:
        1px 1px 2px #084f2b,
        -1px -1px 2px #1ea95e;
}

#a {
    --color: #f03025;
    color: #951b15;
    text-shadow:
        1px 1px 2px #6f120e,
        -1px -1px 2px #ff5149;
}

#b {
    --color: #e9dd34;
    color: #9e9327;
    text-shadow:
        1px 1px 2px #746b1d,
        -1px -1px 2px #d4c84f;
}

.socials {
    display: flex;
    // position: absolute;
    position: relative;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 100px;
    justify-content: center;
    img {
        max-width: 30px;
        position: relative;
        top: 1px;
        &.twitter {
            left: .15px;
        }
        &.telegram {
            left: -1.5px;
        }
        &.dexscreener {
            left: -.25px;
        }
        &.dextools {
            left: -.25px;
        }
    }
    @media only screen and (max-width: 640px) {
        top: 0;
    }
}

footer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    .copyright {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 105px;
        margin: 0 auto;
        @media only screen and (max-width: 540px) {
            bottom: inherit;
            top: 14px;
            position: relative;
        }
    }
    .copyright a {
        color: $white;
        text-decoration: none;
        transform: scale(1);
        transition: .3s all ease;
        text-align: center;
        display: inline-flex;
        margin-left: 10px;
        margin-right: 10px;
        &:hover {
            transform: scale(1.05);
        }
    }
    .copyright img {
        width: 38px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 2px;
        @media only screen and (max-width: 1024px) {
            width: 38px;
            top: 1px;
        }
        @media only screen and (max-width: 540px) {
            top: 0;
        }
    }
    .copyright img.safe {
        width: 28px;
    }
    .inner-footer {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;

        @media only screen and (max-width: 415px) {
            padding-top: 10px;
        }
    }
}


canvas {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 1;
    right: -20vw;
    pointer-events: none;
    opacity: 0;
    animation: fadeIn 0.5s ease-in 0.75s forwards;
    // &.loaded {
    //     opacity: 1;
    // }
    @media only screen and (max-width: 1440px) {
        right: -25vw;
    }
    @media only screen and (max-width: 1280px) {
        // right: -30vw;
    }
    @media only screen and (max-width: 768px) {
        right: 0;
        top: 21vw;
    }
}

#loading-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    text-align: center;
    visibility: hidden;
}

.fade-in {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

@media only screen and (max-width: 950px) and (orientation: landscape) {
    .content-container img {
        max-width: 300px;
    }

    .game-content {
        left: 4%;
        top: 18vh;
    }

    .game-content-container {
        max-width: 300px;
        min-height: 0;
        height: 180px;
        overflow-y: auto;

        p {
            font-size: 15px;
        }
    }

    #buy,
    #play {
        width: 100px;
        height: 60px;
        font-size: 18px;   
    }

    #contract-address,
    #sound-control {
        font-size: 1.25rem;
    }

    #copy-btn img {
        width: 20px;
        height: 20px;
    }

    footer .copyright {
        bottom: 8px;
    }

    #overlay img {
        max-width: 60vw;
    }
}


@media only screen and (max-width: 768px) and (orientation: landscape) {
    .content-container img {
        max-width: 300px;
    }
    .game-content {
        align-items: flex-start;
        left: 0;
        top: 4.5vh;
    }
    .game-content-container {
        max-width: 300px;
        min-height: 0;
        height: 180px;
        overflow-y: auto;
        p {
            font-size: 15px;
        }
    }
    canvas {
        right: -150px;
        top: 5vh;
    }
    #contract-address, #sound-control {
        font-size: 1.25rem;
    }
    #copy-btn img {
        width: 20px;
        height: 20px;
    }
    footer .copyright {
        bottom: 8px;
    }
}

@media only screen and (max-width: 321px) and (orientation: portrait) {

    .game-content-container {
        max-width: 300px;
        min-height: 0;
        height: 180px;
        overflow-y: auto;

        p {
            font-size: 15px;
        }
    }

    .game-content-buttons {
        margin-top: 0;
    }

    #buy,
    #play {
        width: 100px;
        height: 60px;
        font-size: 18px;
    }

    canvas {
        top: 30vw;
    }
}